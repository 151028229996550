import React from "react"

import {
  FaGithub as Github,
  FaInstagram as Insta,
  FaEnvelope as Mail,
  FaLinkedin as Linkedin,

} from "react-icons/fa"

export default function info() {
  return (
    <div className="container">
      <div className="my-5"></div>
      <div className="display-3" style={{ color: "#FF8A00" }}>
        <span style = {{color : "white"}}>Hello, I'm</span> <br />
        Marty Lestock.
      </div>
      <div className="h1 code mt-4 mb-3">async {"{"}</div>
      <div className="text-muted mx-5 my-4 h3 text-justify info">
        One-time educator turned software boffin that loves tech, web development, and 50s Telecaster guitars. 
        <br /> 
        <br />
        A flexible, creative, and self-motivated individual that looks to bridge the gap between technology, education, and coffee.
      </div>
      <div className="h1 code mt-2 mb-3">{"}"}</div>
      <div className="h1 mt-5">
        <a className="mr-5 icon" href="https://www.linkedin.com/in/johnlestock17">
          <Linkedin />
        </a>
        <a className="mr-5 icon" href="https://github.com/RedKardinal">
          <Github />
        </a>
        <a className="mr-5 icon" href="https://instagram.com/zouave_17">
          <Insta />
        </a>
        <a className="mr-5 icon" href="mailto:lestock17@gmail.com">
          <Mail />
        </a>
      </div>
    </div>
  )
}
